var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "detailContent" } },
    [
      _c("h2", [_vm._v("訂單詳細內容")]),
      _c("div", { staticClass: "orderInfo" }, [
        _c("div", { staticClass: "orderInfo_commodity" }, [
          _vm._v(_vm._s(_vm.orderData.ProductName))
        ]),
        _c("div", { staticClass: "orderInfo_canUse" }, [
          _vm._v("\n      適用\n      "),
          _c("span", [_vm._v(_vm._s(_vm.orderData.Appliance || "星盤"))])
        ]),
        _c("div", { staticClass: "orderInfo_price" }, [
          _c("div", { staticClass: "price" }, [
            _c("span", [_vm._v("NT.")]),
            _c("span", [_vm._v(_vm._s(_vm.orderData.Amount))])
          ]),
          _vm._m(0)
        ])
      ]),
      _c(
        "v-container",
        { staticClass: "pb-0" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                _c("div", { staticClass: "info_item" }, [
                  _c("div", { staticClass: "info_title" }, [
                    _vm._v("購買人資訊")
                  ]),
                  _c("p", [
                    _vm._v("姓名: " + _vm._s(_vm.orderData.PurchaserInfo.Name))
                  ]),
                  _c("p", [
                    _vm._v("電話: " + _vm._s(_vm.orderData.PurchaserInfo.Phone))
                  ]),
                  _c("p", [
                    _vm._v(
                      "Email: " + _vm._s(_vm.orderData.PurchaserInfo.Email)
                    )
                  ])
                ]),
                _c("div", { staticClass: "info_item" }, [
                  _c("div", { staticClass: "info_title" }, [
                    _vm._v("訂單資訊")
                  ]),
                  _c("p", [
                    _vm._v(
                      "訂單編號: " + _vm._s(_vm.orderData.OrderInfo.OrderNo)
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      "訂單日期: " + _vm._s(_vm.orderData.OrderInfo.OrderTime)
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      "訂單狀態: " + _vm._s(_vm.orderData.OrderInfo.OrderStatus)
                    )
                  ])
                ])
              ]),
              _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                !_vm.isExchange && _vm.orderData.AstrolabeFriendInfos
                  ? _c("div", { staticClass: "info_item" }, [
                      _c("div", { staticClass: "info_title" }, [
                        _vm._v("星盤好友資訊")
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.orderData.AstrolabeFriendInfos[0].Name)
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.orderData.AstrolabeFriendInfos[0].BirthPlace
                          )
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.orderData.AstrolabeFriendInfos[0].BirthTime
                          )
                        )
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "info_item" }, [
                  _c("div", { staticClass: "info_title" }, [
                    _vm._v("付款資訊")
                  ]),
                  _c("p", [
                    _vm._v(
                      "付款類型: " + _vm._s(_vm.orderData.PaymentInfo.Type)
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      "發票狀態: " + _vm._s(_vm.orderData.PaymentInfo.Status)
                    )
                  ])
                ]),
                _vm.isExchange
                  ? _c("div", { staticClass: "info_item" }, [
                      _c("div", { staticClass: "info_title" }, [
                        _vm._v("\n            兌換碼資訊\n            "),
                        _c(
                          "a",
                          {
                            staticStyle: {
                              "font-size": "0.8em",
                              "margin-left": "4px"
                            },
                            attrs: {
                              href:
                                "https://damoublob.z7.web.core.windows.net/ExchangeFlow.png",
                              target: "__blank"
                            }
                          },
                          [_vm._v("兌換流程")]
                        )
                      ]),
                      _c(
                        "table",
                        [
                          _c("tr", [
                            _c("th", [_vm._v("No.")]),
                            _c("th", [_vm._v("兌換碼")]),
                            _c("th", [_vm._v("複製")])
                          ]),
                          _vm._l(_vm.exchangeInfo, function(item, index) {
                            return _c("tr", { key: index }, [
                              _c("td", [_vm._v(_vm._s(index + 1))]),
                              _c("td", [_vm._v(_vm._s(item))]),
                              _c(
                                "td",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.copyToClipboard(item)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [_vm._v("mdi-content-copy")])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          })
                        ],
                        2
                      )
                    ])
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "contact_us" }, [
        _c(
          "div",
          {
            staticClass: "contact_us_title",
            on: {
              click: function($event) {
                _vm.openContact = !_vm.openContact
              }
            }
          },
          [
            _c("div", [
              _c("span"),
              _c("span", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.openContact,
                    expression: "!openContact"
                  }
                ]
              })
            ]),
            _c("h3", [_vm._v("聯絡我們")])
          ]
        ),
        _c(
          "div",
          { staticClass: "contact_us_area", class: { open: _vm.openContact } },
          [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.content,
                  expression: "content"
                }
              ],
              attrs: {
                name: "",
                id: "",
                cols: "30",
                rows: "10",
                placeholder: "請輸入您的意見或問題..."
              },
              domProps: { value: _vm.content },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.content = $event.target.value
                }
              }
            }),
            _c(
              "button",
              {
                directives: [
                  { name: "preventRepeat", rawName: "v-preventRepeat" }
                ],
                class: { notReady: !_vm.content },
                on: {
                  click: function($event) {
                    return _vm.ContactUs()
                  }
                }
              },
              [_vm._v("\n        寄出\n      ")]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "payMethods" }, [
      _c("img", {
        attrs: { src: require("@/assets/占星小鋪/cc-visa.svg"), alt: "" }
      }),
      _c("img", {
        attrs: { src: require("@/assets/占星小鋪/master.svg"), alt: "" }
      }),
      _c("img", {
        attrs: { src: require("@/assets/占星小鋪/Vector.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }