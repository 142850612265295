<template>
  <div id="detailContent">
    <h2>訂單詳細內容</h2>
    <div class="orderInfo">
      <div class="orderInfo_commodity">{{ orderData.ProductName }}</div>
      <div class="orderInfo_canUse">
        適用
        <span>{{ orderData.Appliance || "星盤" }}</span>
      </div>
      <div class="orderInfo_price">
        <div class="price">
          <span>NT.</span>
          <span>{{ orderData.Amount }}</span>
        </div>
        <div class="payMethods">
          <img src="@/assets/占星小鋪/cc-visa.svg" alt="" />
          <img src="@/assets/占星小鋪/master.svg" alt="" />
          <img src="@/assets/占星小鋪/Vector.svg" alt="" />
        </div>
      </div>
    </div>
    <v-container class="pb-0">
      <v-row>
        <v-col cols="12" sm="6">
          <div class="info_item">
            <div class="info_title">購買人資訊</div>
            <p>姓名: {{ orderData.PurchaserInfo.Name }}</p>
            <p>電話: {{ orderData.PurchaserInfo.Phone }}</p>
            <p>Email: {{ orderData.PurchaserInfo.Email }}</p>
          </div>
          <div class="info_item">
            <div class="info_title">訂單資訊</div>
            <p>訂單編號: {{ orderData.OrderInfo.OrderNo }}</p>
            <p>訂單日期: {{ orderData.OrderInfo.OrderTime }}</p>
            <p>訂單狀態: {{ orderData.OrderInfo.OrderStatus }}</p>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div
            class="info_item"
            v-if="!isExchange && orderData.AstrolabeFriendInfos"
          >
            <div class="info_title">星盤好友資訊</div>
            <p>{{ orderData.AstrolabeFriendInfos[0].Name }}</p>
            <p>{{ orderData.AstrolabeFriendInfos[0].BirthPlace }}</p>
            <p>{{ orderData.AstrolabeFriendInfos[0].BirthTime }}</p>
          </div>
          <div class="info_item">
            <div class="info_title">付款資訊</div>
            <p>付款類型: {{ orderData.PaymentInfo.Type }}</p>
            <p>發票狀態: {{ orderData.PaymentInfo.Status }}</p>
            <!-- <p>發票號碼: {{ orderData.PaymentInfo.InvoiceNumber || "無" }}</p> -->
          </div>
          <!--  商品券兌換碼 -->
          <div class="info_item" v-if="isExchange">
            <div class="info_title">
              兌換碼資訊
              <a
                href="https://damoublob.z7.web.core.windows.net/ExchangeFlow.png"
                target="__blank"
                style="font-size: 0.8em; margin-left: 4px"
                >兌換流程</a
              >
            </div>
            <table>
              <tr>
                <th>No.</th>
                <th>兌換碼</th>
                <th>複製</th>
              </tr>
              <tr v-for="(item, index) in exchangeInfo" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item }}</td>
                <td>
                  <v-btn icon @click="copyToClipboard(item)">
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </td>
              </tr>
            </table>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="contact_us">
      <div class="contact_us_title" @click="openContact = !openContact">
        <div><span></span><span v-show="!openContact"></span></div>
        <h3>聯絡我們</h3>
      </div>
      <div class="contact_us_area" :class="{ open: openContact }">
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="請輸入您的意見或問題..."
          v-model="content"
        ></textarea>
        <button
          @click="ContactUs()"
          v-preventRepeat
          :class="{ notReady: !content }"
        >
          寄出
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["orderData"],
  components: {},
  data() {
    return {
      openContact: false,
      content: "",
      exchangeInfo: [],
    };
  },
  computed: {
    isExchange() {
      return this.orderData.Type === 3;
    },
  },
  created() {
    if (this.isExchange) {
      this.getExchangeInfo();
    }
  },
  methods: {
    ContactUs() {
      this.$API
        .POST_ContactUsMail({
          OrderNo: parseInt(this.orderData.OrderInfo.OrderNo),
          Content: this.content,
        })
        .then(() => {
          this.$emit("sendEmail");
        })
        .catch(() => alert("伺服器忙線中，請稍後再試"));
    },
    getExchangeInfo() {
      this.$API
        .GET_ExchangeCode(this.orderData.OrderInfo.OrderNo)
        .then(({ data }) => {
          // console.log("data", data);
          this.exchangeInfo = data.Data;
        });
    },
    copyToClipboard(text) {
      // 用web api的方法
      navigator.clipboard.writeText(text).then(() => {
        this.$store.commit("update_toast", [`${text} 複製成功`]);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_mixin.scss";
#detailContent {
  width: 100%;
  font-weight: 700;
  position: relative;
  h2 {
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.08em;
    color: $theme-color-2;
    margin-bottom: 24px;
  }
  .orderInfo {
    width: 100%;
    margin-top: 20px;
    @include pad {
      margin-top: 24px;
    }
    .orderInfo_commodity {
      font-size: 24px;
      line-height: 36px;
      color: $secondary-color-1;
    }
    .orderInfo_canUse {
      margin-top: 20px;
      font-size: 18px;
      line-height: 26px;
      color: #9b9b9b;
      span {
        padding-left: 10px;
        font-size: 18px;
        line-height: 26px;
        color: $secondary-color-1;
      }
    }
    .orderInfo_price {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      position: relative;
      margin-top: 20px;
      padding-bottom: 15px;
      border-bottom: 1.5px solid #d9d9d9;
      .price {
        display: flex;
        align-items: flex-end;
        color: $secondary-color-1;
        & > span:nth-of-type(1) {
          font-size: 16px;
          padding-right: 4px;
        }
        & > span:nth-of-type(2) {
          font-size: 40px;
          line-height: 1;
        }
      }
      .payMethods {
        display: flex;
        padding-right: 80px;
        > img {
          height: 16px;
          margin-left: 6px;
        }
      }
    }
  }
  .info_item {
    margin-bottom: 20px;
    .info_title {
      color: $secondary-color-1;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 12px;
    }
    > p {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 22px;
      color: #9b9b9b;
      white-space: nowrap;
    }
    table {
      max-width: 100%;
      max-height: 150px;
      overflow-y: auto;
      overflow-x: hidden;
      display: inline-block;
      @include mobile {
        max-height: unset;
        overflow-y: visible;
      }
      th {
        font-size: 15px;
        padding: 0 10px;
        color: $theme-color-2;
        position: sticky;
        top: 0;
        background-color: #f0f2f4;
        z-index: 2;
        text-align: left;
      }
      td {
        font-size: 13px;
        line-height: 22px;
        color: #9b9b9b;
        padding: 0 10px;
      }
      & td:first-child,
      & th:first-child {
        width: 12px;
      }
    }
  }
  //聯絡我們
  .contact_us {
    width: 100%;
    .contact_us_title {
      display: flex;
      align-items: center;
      cursor: pointer;
      h3 {
        padding-left: 14px;
        font-size: 20px;
        line-height: 36px;
        color: #9b9b9b;
      }
      div {
        width: 32px;
        height: 32px;
        background: #f0f2f4;
        box-shadow: -2px -2px 4px #ffffff, -2px -2px 4px #ffffff,
          4px 4px 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        position: relative;
        span {
          display: inline-block;
          height: 2px;
          width: 16px;
          background-color: $theme-color-2;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          &:nth-of-type(2) {
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
      }
    }
    .contact_us_area {
      margin-top: 22px;
      font-size: 16px;
      line-height: 20px;
      text-align: justify;
      color: #9b9b9b;
      height: 0;
      visibility: hidden;
      &.open {
        height: auto;
        visibility: visible;
      }
      & > textarea {
        width: 100%;
        height: 118px;
        padding: 14px 17px;
        background: #f0f2f4;
        box-shadow: inset -2px -4px 10px #ffffff, inset -2px -4px 10px #ffffff,
          inset 0px 0px 10px rgba(0, 0, 0, 0.45);
        &:focus {
          border: none;
          outline: none;
        }
      }
      & > button {
        margin-top: 20px;
        margin-left: auto;
        @include btn-default(176px, 56px);
        @include mobile {
          margin: 20px auto;
        }
      }
    }
  }
}
</style>
